import React from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  // Features with unique images
  const features = [
    {
      name: "පසුගිය ප්‍රශ්න පත්‍ර",
      path: "/pastpapers",
      image: "feature01.jpg",
    },
    {
      name: "පළාත් ප්‍රශ්න පත්‍ර",
      path: "/ol_province",
      image: "feature02.jpg",
    },
    {
      name: "පෙරහුරු ප්‍රශ්න පත්‍ර",
      path: "/",
      image: "feature03.jpg",
    },
  ];

  return (
    <div
      className="min-h-screen flex items-center justify-center bg-gray-100"
      style={{
        backgroundImage: `url('assets/background1.jpg')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="text-center">
        {/* Headline and Tagline */}
        <div className="mb-6">
          {/* <h1 className="text-4xl font-bold mb-2 text-red-500">
            Viduhala.lk ExamWizard
          </h1> */}

          <h1 className="text-3xl mb-2 ">අපොස සාමාන්‍යපෙළ විභාගය</h1>
          <p className="text-black-400 text-lg">
            ඔබ කැමති ප්‍රශ්නපත්‍ර වර්ගය පහතින් තෝරන්න
          </p>
        </div>

        {/* Fixed 4-Column Grid */}
        <div className="grid grid-cols-3 gap-6 max-w-4xl">
          {features.map((feature, index) => (
            <div
              key={index}
              onClick={() => navigate(feature.path)}
              className="bg-white shadow-md hover:shadow-lg rounded-lg p-4 cursor-pointer transition-all"
            >
              <img
                src={`assets/${feature.image}`}
                alt={feature.name}
                className="w-full h-40 object-cover rounded-lg mb-2"
              />
              <h2 className="text-lg font-semibold text-center">
                {feature.name}
              </h2>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
