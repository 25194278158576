import React from "react";
import "./App.css";

import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Quiz from "./Quiz";

import LandingPage from "./LandingPage";
import Result from "./Result";
import Home from "./Home";
import OlevelPage from "./OlevelPage";
import AlevelPage from "./AlevelPage";
import ALPastpaperSelector from "./components/ALPastpaperSelector";
import AlQuiz from "./AlQuiz";
import SmartProvincePaperSelector from "./components/SmartProvincePaperSelector";
import AlQuizPrvince from "./AlQuizPrvince";
import OLSmartProvincePaperSelector from "./components/OLSmartProvincePaperSelector";
import OlQuizPrvince from "./OlQuizPrvince";

import PastpaperSelector from "./components/PastpaperSelector";
// import SubjectYearPage from "./components/SubjectYearPage";
// import ExamPage from "./components/ExamPage"; // Assume this is already implemented

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/LandingPage" element={<LandingPage />} />
        <Route path="/quiz" element={<Quiz />} />
        <Route path="/result" element={<Result />} />
        <Route path="/pastpapers" element={<PastpaperSelector />} />
        <Route path="/olevel" element={<OlevelPage />} />
        <Route path="/alevel" element={<AlevelPage />} />
        <Route path="/al_pastpapers" element={<ALPastpaperSelector />} />
        <Route path="/al_quiz" element={<AlQuiz />} />
        <Route path="/al_province" element={<SmartProvincePaperSelector />} />
        <Route path="/ol_province" element={<OLSmartProvincePaperSelector />} />
        <Route path="/AlQuizPrvince" element={<AlQuizPrvince />} />
        <Route path="/OlQuizPrvince" element={<OlQuizPrvince />} />
        {/* <Route path="/pastpapers/:subjectYear" element={<SubjectYearPage />} /> */}
        {/* <Route path="/exam" element={<ExamPage />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
