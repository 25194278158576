import React from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  // Features with unique images section
  const features = [
    {
      name: "සාමාන්‍ය පෙළ",
      path: "/olevel",
      image: "olevel.png",
    },
    {
      name: "උසස් පෙළ",
      path: "/alevel",
      image: "alevel2.png",
    },
  ];

  return (
    <div
      className="min-h-screen flex items-center justify-center bg-gray-100"
      style={{
        backgroundImage: `url('assets/background1.jpg')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="text-center">
        {/* Headline and Tagline */}
        <div className="mb-6">
          {/* <h1 className="text-4xl font-bold mb-2 text-red-500">
            Viduhala.lk ExamWizard
          </h1> */}

          <h1 className="text-3xl mb-2 ">විභාග වර්ගය තෝරන්න</h1>
          <p className="text-black-400 text-lg">
            ඔබ ඉදිරිපත් වන විභාගය පහතින් තෝරන්න
          </p>
        </div>

        {/* Fixed 4-Column Grid */}
        <div className="grid grid-cols-2 gap-6 max-w-4xl">
          {features.map((feature, index) => (
            <div
              key={index}
              onClick={() => navigate(feature.path)}
              className="bg-white shadow-md hover:shadow-lg rounded-lg p-4 cursor-pointer transition-all"
            >
              <img
                src={`assets/${feature.image}`}
                alt={feature.name}
                className="w-full h-60 object-cover rounded-lg mb-2"
              />
              <h2 className="text-lg font-semibold text-center">
                {feature.name}
              </h2>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
