import React, { useState, useEffect } from "react";
import { useLocation as useRouteLocation, useNavigate } from "react-router-dom";

// import Navbar from "./Navbar";

const Quiz = () => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [timer, setTimer] = useState(3600); // 1-hour timer
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [score, setScore] = useState(0);
  const [reviewMode, setReviewMode] = useState(false);

  // Fetch category and author from the URL state (not search params)
  const navigate = useNavigate();
  const routeLocation = useRouteLocation();
  const { category, author, fileName } = routeLocation.state || {};
  // Load questions from JSON file and apply filtering
  useEffect(() => {
    if (!fileName) return; // Ensure we have a file name

    fetch(`./data/olevel/pastpaper/${fileName}.json`)
      .then((response) => response.json())
      .then((data) => {
        let filteredQuestions = data;

        // Apply category filter if required
        if (category) {
          filteredQuestions = filteredQuestions.filter(
            (q) => q.category === category
          );
        }

        // Apply author filter if required
        if (author) {
          filteredQuestions = filteredQuestions.filter(
            (q) => q.author === author
          );
        }

        setQuestions(filteredQuestions.slice(0, 40));
      })
      .catch((error) => console.error("Error loading questions:", error));

    const countdown = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 0) {
          clearInterval(countdown);
          handleFinishQuiz();
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, [category, author, fileName]);

  const handleAnswerSelection = (option) => {
    setSelectedAnswer(option);
  };

  const handleNextQuestion = () => {
    const currentAnswer = {
      question: questions[currentQuestion].question,
      img: questions[currentQuestion].img,
      options: [
        questions[currentQuestion].option1,
        questions[currentQuestion].option2,
        questions[currentQuestion].option3,
        questions[currentQuestion].option4,
      ],
      correctAnswer: questions[currentQuestion].correctOption,
      selectedAnswer,
    };

    setAnswers((prev) => [...prev, currentAnswer]);

    if (selectedAnswer === questions[currentQuestion].correctOption) {
      setScore((prev) => prev + 1);
    }

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion((prev) => prev + 1);
      setSelectedAnswer(null);
    } else {
      handleFinishQuiz();
    }
  };

  const handleFinishQuiz = () => {
    setQuizCompleted(true);
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const handleRetakeQuiz = () => {
    setQuestions([]);
    setCurrentQuestion(0);
    setTimer(3600);
    setSelectedAnswer(null);
    setAnswers([]);
    setQuizCompleted(false);
    setScore(0);
    setReviewMode(false);

    fetch(`./data/${fileName}.json`)
      .then((response) => response.json())
      .then((data) => {
        let filteredQuestions = data;

        // Apply category filter again
        if (category) {
          filteredQuestions = filteredQuestions.filter(
            (q) => q.category === category
          );
        }

        // Apply author filter again
        if (author) {
          filteredQuestions = filteredQuestions.filter(
            (q) => q.author === author
          );
        }

        setQuestions(filteredQuestions.slice(0, 40)); // Limit to 40 questions
      })
      .catch((error) => console.error("Error loading questions:", error));
  };

  const handleToggleReviewMode = () => {
    setReviewMode(!reviewMode);
  };

  const handleGoBack = () => navigate(-1); // Navigate to the previous page
  const handleGoHome = () => navigate("/"); // Navigate to the home page

  if (quizCompleted) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <div className="max-w-lg w-full bg-white p-6 rounded-lg shadow-lg text-center">
          <h2 className="text-2xl font-semibold mb-4">Quiz Completed!</h2>
          <p className="text-lg">
            Your Score: {score} / {questions.length}
          </p>
          <div className="mt-6 space-x-4">
            <button
              onClick={handleToggleReviewMode}
              className="px-4 py-2 bg-blue-500 text-white rounded-lg"
            >
              {reviewMode ? "Hide Review" : "Review Answers"}
            </button>
            <button
              onClick={handleRetakeQuiz}
              className="px-4 py-2 bg-green-500 text-white rounded-lg"
            >
              Retake Quiz
            </button>
          </div>

          {reviewMode && (
            <div className="mt-8">
              <h3 className="text-xl font-bold mb-4">Answer Review</h3>
              <div className="space-y-6">
                {answers.map((answer, index) => (
                  <div key={index} className="p-4 bg-gray-200 rounded-lg">
                    {answer.img && (
                      <div className="mb-4">
                        <img
                          src={answer.img}
                          alt={`Question ${index + 1}`}
                          className="w-full h-48 object-contain rounded-lg"
                        />
                      </div>
                    )}
                    <h4 className="font-semibold">
                      Q{index + 1}: {answer.question}
                    </h4>
                    {answer.options.map((option, idx) => (
                      <p
                        key={idx}
                        className={`pl-2 ${
                          idx + 1 === answer.correctAnswer
                            ? "text-green-500 font-bold"
                            : idx + 1 === answer.selectedAnswer &&
                              idx + 1 !== answer.correctAnswer
                            ? "text-red-500 font-semibold"
                            : ""
                        }`}
                      >
                        {idx + 1}. {option}
                      </p>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <div className="max-w-lg w-full bg-white p-6 rounded-lg shadow-lg relative">
          {/* Navigation menu */}
          <div className="absolute top-0 left-0 right-0 flex justify-between items-center p-4 bg-gray-800 text-white rounded-t-lg">
            <button
              onClick={handleGoBack}
              className="px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded-lg"
            >
              Back
            </button>
            <h1 className="text-lg font-bold">Quiz</h1>
            <button
              onClick={handleGoHome}
              className="px-4 py-2 bg-green-600 hover:bg-green-700 rounded-lg"
            >
              Home
            </button>
          </div>

          {/* Quiz content */}
          <div className="mt-16">
            {questions.length > 0 && (
              <>
                {questions[currentQuestion].img && (
                  <div className="mb-4">
                    <img
                      src={questions[currentQuestion].img}
                      alt="Question"
                      className="w-full h-48 object-contain rounded-lg"
                    />
                  </div>
                )}
                <h2 className="text-xl font-semibold mb-4">
                  Question {currentQuestion + 1}:{" "}
                  {questions[currentQuestion].question}
                </h2>
                <div className="space-y-4">
                  {["option1", "option2", "option3", "option4"].map(
                    (option, index) => (
                      <button
                        key={index}
                        onClick={() => handleAnswerSelection(index + 1)}
                        className={`block w-full py-2 px-4 text-left rounded-lg ${
                          selectedAnswer === index + 1
                            ? "bg-blue-500 text-white"
                            : "bg-gray-200"
                        }`}
                      >
                        {questions[currentQuestion][option]}
                      </button>
                    )
                  )}
                </div>
                <div className="mt-6 flex justify-between items-center">
                  <button
                    onClick={handleNextQuestion}
                    className="px-4 py-2 bg-green-500 text-white rounded-lg"
                    disabled={selectedAnswer === null}
                  >
                    {currentQuestion === questions.length - 1
                      ? "Submit Quiz"
                      : "Next Question"}
                  </button>
                  <div className="text-lg font-semibold">
                    Time Left: {formatTime(timer)}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Quiz;
