import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function PastpaperSelector() {
  const navigate = useNavigate();

  const subjects = [
    { label: "ගණිතය", value: "maths" },
    { label: "විද්‍යාව", value: "science" },
    { label: "English", value: "english" },
    { label: "ඉතිහාසය", value: "history" },
    { label: "භූගෝල විද්‍යාව", value: "geography" },
    { label: "සෞඛ්‍ය", value: "helth" },
    { label: "බුද්ධ ධර්මය", value: "buddhist" },
    { label: "තොරතුරු හා සන්නිවේදන තාක්ෂණය", value: "ict" },
    { label: "සිංහල", value: "sinhala" },
    { label: "පුරවැසි අධ්‍යාපනය", value: "civic" },
  ];

  const allYears = [
    "2012",
    "2013",
    "2014",
    "2015",
    "2016",
    "2017",
    "2018",
    "2019",
    "2020",
    "2021",
    "2022",
    "2023",
  ];

  // State
  const [subject, setSubject] = useState("");
  const [year, setYear] = useState("");
  const [availableYears, setAvailableYears] = useState([]);
  const [isLoadingYears, setIsLoadingYears] = useState(false);

  // Check available years for selected subject
  useEffect(() => {
    if (!subject) return;

    let isMounted = true;
    setIsLoadingYears(true);

    async function checkAvailableYears() {
      try {
        const foundYears = [];

        // Check each year sequentially
        for (const year of allYears) {
          if (!isMounted) break;

          const filename = `${subject}${year}.json`;
          const filePath = `./data/olevel/pastpaper/${filename}`;

          try {
            const response = await fetch(filePath);
            if (!response.ok) {
              // Skip if response is not ok (404 etc)
              continue;
            }
            // Try to parse JSON to ensure it's valid
            await response.json();
            foundYears.push(year);
          } catch (error) {
            // Skip if fetch fails or JSON is invalid
            continue;
          }
        }

        if (isMounted) {
          // Sort years in descending order (newest first)
          setAvailableYears(foundYears.sort((a, b) => b - a));
          // Reset year selection if previously selected year is not available
          if (year && !foundYears.includes(year)) {
            setYear("");
          }
        }
      } catch (error) {
        console.error("Error checking available years:", error);
        if (isMounted) {
          setAvailableYears([]);
        }
      } finally {
        if (isMounted) {
          setIsLoadingYears(false);
        }
      }
    }

    checkAvailableYears();

    return () => {
      isMounted = false;
    };
  }, [subject]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!subject || !year) return;

    const filename = `${subject}${year}`;
    navigate("/quiz", { state: { fileName: filename } });
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="w-full max-w-md bg-white rounded-xl shadow-md p-6">
        <h1 className="text-2xl font-bold text-gray-800 mb-6 text-center">
          Select Past Paper
        </h1>

        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          {/* Subject Selection */}
          <div className="flex flex-col">
            <label
              htmlFor="subjectSelect"
              className="font-semibold text-gray-700 mb-1"
            >
              Subject:
            </label>
            <select
              id="subjectSelect"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              <option value="">Select a subject</option>
              {subjects.map((subj) => (
                <option key={subj.value} value={subj.value}>
                  {subj.label}
                </option>
              ))}
            </select>
          </div>

          {/* Year Selection */}
          {subject && (
            <div className="flex flex-col">
              <label
                htmlFor="yearSelect"
                className="font-semibold text-gray-700 mb-1"
              >
                Year:
              </label>
              <select
                id="yearSelect"
                value={year}
                onChange={(e) => setYear(e.target.value)}
                disabled={isLoadingYears || availableYears.length === 0}
                className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 disabled:bg-gray-100"
              >
                <option value="">Select a year</option>
                {availableYears.map((yr) => (
                  <option key={yr} value={yr}>
                    {yr}
                  </option>
                ))}
              </select>
              {isLoadingYears && (
                <span className="text-sm text-gray-500 mt-1">
                  Checking available papers...
                </span>
              )}
              {!isLoadingYears && availableYears.length === 0 && subject && (
                <span className="text-sm text-red-500 mt-1">
                  No papers available for this subject
                </span>
              )}
            </div>
          )}

          {/* Submit Button */}
          <button
            type="submit"
            disabled={!subject || !year || isLoadingYears}
            className="mt-4 py-2 px-4 rounded-full text-white font-semibold bg-gradient-to-r from-indigo-500 to-indigo-600 hover:to-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:outline-none transform hover:scale-105 transition-transform disabled:opacity-50 disabled:cursor-not-allowed disabled:transform-none"
          >
            {isLoadingYears ? "Checking availability..." : "Load Past Paper"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default PastpaperSelector;
