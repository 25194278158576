import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function SmartProvincePaperSelector() {
  const navigate = useNavigate();

  // Base data
  const subjects = [
    { label: "ගණිතය", value: "maths" },
    { label: "ජීව විද්‍යාව", value: "bio" },
    { label: "භෞතික විද්‍යාව", value: "physics" },
  ];

  const allYears = ["2022", "2023"];

  const allProvinces = [
    { label: "බස්නාහිර පළාත", value: "western" },
    { label: "දකුණු පළාත", value: "southern" },
    { label: "වයඹ පළාත", value: "northWestern" },
    { label: "මධ්‍යම පළාත", value: "central" },
    { label: "සබරගමුව පළාත", value: "sabaragamuwa" },
    { label: "උතුරුමැද පළාත", value: "northCentral" },
    { label: "ඌව පළාත", value: "uva" },
  ];

  // State
  const [subject, setSubject] = useState("");
  const [year, setYear] = useState("");
  const [province, setProvince] = useState("");
  const [paperNumber, setPaperNumber] = useState(1);

  // Available options state
  const [availableYears, setAvailableYears] = useState([]);
  const [availableProvinces, setAvailableProvinces] = useState([]);
  const [availablePapers, setAvailablePapers] = useState([]);

  // Loading states
  const [isLoadingYears, setIsLoadingYears] = useState(false);
  const [isLoadingProvinces, setIsLoadingProvinces] = useState(false);
  const [isLoadingPapers, setIsLoadingPapers] = useState(false);

  // Check available years for selected subject
  useEffect(() => {
    if (!subject) return;

    let isMounted = true;
    setIsLoadingYears(true);

    async function checkAvailableYears() {
      try {
        const foundYears = [];

        // Check each year sequentially
        for (const year of allYears) {
          if (!isMounted) break;

          // Try to find at least one province paper for this year
          for (const prov of allProvinces) {
            const filename = `${subject}${year}${prov.value}.json`;
            const filePath = `./data/alevel/province/${filename}`;

            try {
              const response = await fetch(filePath);
              if (response.ok) {
                // If we find at least one province paper, this year is valid
                await response.json(); // Verify JSON is valid
                foundYears.push(year);
                break; // Break inner loop once we find a valid paper
              }
            } catch (error) {
              continue; // Continue checking other provinces
            }
          }
        }

        if (isMounted) {
          // Sort years in descending order (newest first)
          setAvailableYears(foundYears.sort((a, b) => b - a));
          if (year && !foundYears.includes(year)) {
            setYear("");
          }
        }
      } catch (error) {
        console.error("Error checking available years:", error);
        if (isMounted) {
          setAvailableYears([]);
        }
      } finally {
        if (isMounted) {
          setIsLoadingYears(false);
        }
      }
    }

    checkAvailableYears();

    return () => {
      isMounted = false;
    };
  }, [subject]);

  // Check available provinces for selected subject and year
  useEffect(() => {
    if (!subject || !year) return;

    let isMounted = true;
    setIsLoadingProvinces(true);

    async function checkAvailableProvinces() {
      try {
        const foundProvinces = [];

        // Check each province
        for (const prov of allProvinces) {
          if (!isMounted) break;

          const filename = `${subject}${year}${prov.value}.json`;
          const filePath = `./data/alevel/province/${filename}`;

          try {
            const response = await fetch(filePath);
            if (response.ok) {
              await response.json(); // Verify JSON is valid
              foundProvinces.push(prov);
            }
          } catch (error) {
            continue; // Skip if file doesn't exist or is invalid
          }
        }

        if (isMounted) {
          setAvailableProvinces(foundProvinces);
          if (province && !foundProvinces.some((p) => p.value === province)) {
            setProvince("");
          }
        }
      } catch (error) {
        console.error("Error checking available provinces:", error);
        if (isMounted) {
          setAvailableProvinces([]);
        }
      } finally {
        if (isMounted) {
          setIsLoadingProvinces(false);
        }
      }
    }

    checkAvailableProvinces();

    return () => {
      isMounted = false;
    };
  }, [subject, year]);

  // Check for multiple papers
  useEffect(() => {
    if (!subject || !year || !province) return;

    let isMounted = true;
    setIsLoadingPapers(true);

    async function checkMultiplePapers() {
      try {
        // For now, we'll assume there's only one paper per combination
        // You can expand this logic if you need to handle multiple papers
        const papers = [1];

        if (isMounted) {
          setAvailablePapers(papers);
          setPaperNumber(1);
        }
      } catch (error) {
        console.error("Error checking available papers:", error);
        if (isMounted) {
          setAvailablePapers([]);
        }
      } finally {
        if (isMounted) {
          setIsLoadingPapers(false);
        }
      }
    }

    checkMultiplePapers();

    return () => {
      isMounted = false;
    };
  }, [subject, year, province]);

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
    setYear("");
    setProvince("");
  };

  const handleYearChange = (e) => {
    setYear(e.target.value);
    setProvince("");
  };

  const handleProvinceChange = (e) => {
    setProvince(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!subject || !year || !province || availablePapers.length === 0) return;

    const filename = `${subject}${year}${province}${
      availablePapers.length > 1 ? paperNumber : ""
    }`;
    navigate("/AlQuizPrvince", { state: { fileName: filename } });
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="w-full max-w-md bg-white rounded-xl shadow-md p-6">
        <h1 className="text-2xl font-bold text-gray-800 mb-6 text-center">
          Select Province Paper
        </h1>

        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          {/* Subject Selection */}
          <div className="flex flex-col">
            <label
              htmlFor="subjectSelect"
              className="font-semibold text-gray-700 mb-1"
            >
              Subject:
            </label>
            <select
              id="subjectSelect"
              value={subject}
              onChange={handleSubjectChange}
              className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              <option value="">Select a subject</option>
              {subjects.map((subj) => (
                <option key={subj.value} value={subj.value}>
                  {subj.label}
                </option>
              ))}
            </select>
          </div>

          {/* Year Selection */}
          {subject && (
            <div className="flex flex-col">
              <label
                htmlFor="yearSelect"
                className="font-semibold text-gray-700 mb-1"
              >
                Year:
              </label>
              <select
                id="yearSelect"
                value={year}
                onChange={handleYearChange}
                disabled={isLoadingYears || availableYears.length === 0}
                className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 disabled:bg-gray-100"
              >
                <option value="">Select a year</option>
                {availableYears.map((yr) => (
                  <option key={yr} value={yr}>
                    {yr}
                  </option>
                ))}
              </select>
              {isLoadingYears && (
                <span className="text-sm text-gray-500 mt-1">
                  Checking available papers...
                </span>
              )}
              {!isLoadingYears && availableYears.length === 0 && subject && (
                <span className="text-sm text-red-500 mt-1">
                  No papers available for this subject
                </span>
              )}
            </div>
          )}

          {/* Province Selection */}
          {year && (
            <div className="flex flex-col">
              <label
                htmlFor="provinceSelect"
                className="font-semibold text-gray-700 mb-1"
              >
                Province:
              </label>
              <select
                id="provinceSelect"
                value={province}
                onChange={handleProvinceChange}
                disabled={isLoadingProvinces || availableProvinces.length === 0}
                className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 disabled:bg-gray-100"
              >
                <option value="">Select a province</option>
                {availableProvinces.map((prov) => (
                  <option key={prov.value} value={prov.value}>
                    {prov.label}
                  </option>
                ))}
              </select>
              {isLoadingProvinces && (
                <span className="text-sm text-gray-500 mt-1">
                  Checking available provinces...
                </span>
              )}
              {!isLoadingProvinces &&
                availableProvinces.length === 0 &&
                year && (
                  <span className="text-sm text-red-500 mt-1">
                    No provinces available for this year
                  </span>
                )}
            </div>
          )}

          {/* Submit Button */}
          <button
            type="submit"
            disabled={!subject || !year || !province || isLoadingPapers}
            className="mt-4 py-2 px-4 rounded-full text-white font-semibold bg-gradient-to-r from-indigo-500 to-indigo-600 hover:to-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:outline-none transform hover:scale-105 transition-transform disabled:opacity-50 disabled:cursor-not-allowed disabled:transform-none"
          >
            {isLoadingPapers
              ? "Checking availability..."
              : "Load Province Paper"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default SmartProvincePaperSelector;
